import Vue from "vue"

import { DownloadTypes } from "@evercam/shared/types"
import "file-saver"
import jsPDF from "jspdf"
import { toTitleCase } from "@evercam/shared/utils"

type DownloadImageParams = {
  imageSrc: string
  watermarkLogoUrl?: string
  watermarkText?: string
  title?: string
  saveAs: DownloadTypes
  pdfData?: object
  origin?: string
}

type CopyImageParams = {
  imageSrc: string
  watermarkLogoUrl?: string
  watermarkText?: string
}

type GeneratePdfParams = {
  image?: string | null
  pdfData?: Record<string, unknown>
  origin?: string
  fileName?: string
  imageAspectRatio?: number
}

type CreatePdfTemplateParams = {
  pdfData: Record<string, unknown>
  origin: string
  image: string | null
  pdfWidth: number
  pdfHeight: number
  imageAspectRatio: number
}

type DrawImageToCanvasResult = {
  canvas: HTMLCanvasElement
  context: CanvasRenderingContext2D
}

declare module "vue/types/vue" {
  interface Vue {
    copyToClipboard: (params: CopyImageParams) => Promise<void>
    downloadImage: (params: DownloadImageParams) => Promise<void>
    generatePdf: (params: GeneratePdfParams) => void
    createPdfTemplate: (params: CreatePdfTemplateParams) => HTMLElement
    resetCanvas: () => void
    addWatermark: (logoUrl: string, text: string) => Promise<void>
    addLogoWatermark: (logoUrl: string) => Promise<void>
    addTextWatermark: (text: string) => void
    convertCanvasToImageBlob: (canvas: HTMLCanvasElement) => Promise<Blob>
    drawImageToCanvas: (imageSrc: string) => Promise<DrawImageToCanvasResult>
    loadImage: (src: string) => Promise<HTMLImageElement>
  }
}

export default Vue.extend({
  name: "ImageUtils",
  data() {
    return {
      canvas: null as unknown as HTMLCanvasElement,
      context: null as unknown as CanvasRenderingContext2D,
    }
  },
  methods: {
    async copyToClipboard({
      imageSrc = null,
      watermarkLogoUrl = "",
      watermarkText = "",
    } = {}) {
      try {
        if (!imageSrc) {
          throw new Error("Unable to get image src")
        }

        const { canvas, context } = await this.drawImageToCanvas(imageSrc)
        this.canvas = canvas
        this.context = context

        if (watermarkText || watermarkLogoUrl) {
          await this.addWatermark(watermarkLogoUrl, watermarkText)
        }

        const blob = await this.convertCanvasToImageBlob(this.canvas)

        if (!blob) {
          throw new Error("Unable to get image blob")
        }

        const clipboardItem = new ClipboardItem({ "image/png": blob as Blob })
        await navigator.clipboard.write([clipboardItem])
      } catch (error) {
        throw new Error(
          `Failed to copy to clipboard: ${(error as Error)?.message}`
        )
      } finally {
        this.resetCanvas()
      }
    },

    async downloadImage({
      imageSrc = null,
      watermarkLogoUrl = "",
      watermarkText = "",
      title = "",
      saveAs = DownloadTypes.Jpeg,
      pdfData = {},
      origin = "",
    } = {}) {
      try {
        if (!imageSrc) {
          throw new Error("Unable to get image src")
        }

        const { canvas, context } = await this.drawImageToCanvas(imageSrc)
        this.canvas = canvas
        this.context = context

        if (watermarkText || watermarkLogoUrl) {
          await this.addWatermark(watermarkLogoUrl, watermarkText)
        }

        const imageAspectRatio = this.canvas.width / this.canvas.height
        const image = this.canvas.toDataURL("image/jpeg")

        if (!image) {
          throw new Error("Unable to get image data")
        }

        if (saveAs === DownloadTypes.Pdf) {
          await this.generatePdf({
            image,
            pdfData,
            origin,
            fileName: `${title}.pdf`,
            imageAspectRatio,
          })
        } else if (saveAs === DownloadTypes.Jpeg) {
          global.saveAs(image, `${title}.jpeg`)
        }
      } catch (error) {
        throw new Error(
          `Failed to download image: ${(error as Error)?.message}`
        )
      } finally {
        this.resetCanvas()
      }
    },
    generatePdf({
      image = null,
      pdfData = {},
      origin = "",
      fileName = "",
      imageAspectRatio,
    }: GeneratePdfParams = {}) {
      const pdf = new jsPDF("p", "pt", "A4")
      const pdfWidth = pdf.internal.pageSize.width - 30
      const pdfHeight = pdf.internal.pageSize.height - 60
      const pdfContent = this.createPdfTemplate({
        pdfData,
        origin,
        image,
        pdfWidth,
        pdfHeight,
        imageAspectRatio,
      })

      this.$nextTick(() => {
        pdf.html(pdfContent, {
          margin: [30, 15],
          callback: (doc) => doc.save(fileName),
        })
      })
    },
    createPdfTemplate({
      pdfData = {},
      origin = "",
      image = null,
      pdfWidth = 0,
      pdfHeight = 0,
      imageAspectRatio = 1,
    }: Partial<CreatePdfTemplateParams> = {}): HTMLElement {
      const container = document.createElement("div")
      container.style.width = `${pdfWidth}px`
      container.style.margin = "0 auto"
      container.style.fontFamily = "'Arial', sans-serif"
      container.style.fontSize = "10px"

      const dataHtml = Object.entries(pdfData)
        .map(
          ([key, value]) =>
            `<p style="margin: 2px 0"><strong>${toTitleCase(
              key
            )}</strong>: ${value}</p>`
        )
        .join("")

      const originText = toTitleCase(origin)

      const originTextHeight = 25
      const dataHeight = dataHtml.split("</p>").length * 15 + originTextHeight

      let imageWidth = "100%",
        imageHeight = "auto"

      if (imageAspectRatio <= 1) {
        imageWidth = "auto"
        imageHeight = `${pdfHeight - dataHeight}px`
      }

      container.innerHTML = `
        <div style="box-sizing: border-box">
          <!-- Header -->
          <div style="display: flex; justify-content: space-between;">
            <!-- Data -->
            <div>
              <h3 style="margin-bottom: 10px; font-size: 14px">${originText}</h3>
              ${dataHtml}
            </div>
            <!-- Logo -->
            <div style="width: 160px; text-align: center">
              <img src="/evercam_logo.png" alt="Logo" style="width: 100%; max-width: 160px; object-fit: contain" />
            </div>
          </div>
          <!-- Image -->
          ${
            image
              ? `<img src="${image}" alt="Main" style="width: ${imageWidth}; height: ${imageHeight}; margin-top: 15px"/>`
              : ""
          }
        </div>
      `

      return container
    },
    resetCanvas() {
      this.canvas = null as unknown as HTMLCanvasElement
      this.context = null as unknown as CanvasRenderingContext2D
    },

    async addWatermark(logoUrl: string, text: string) {
      await this.addLogoWatermark(logoUrl)
      this.addTextWatermark(text)
    },
    async addLogoWatermark(logoUrl: string) {
      if (!logoUrl) {
        return
      }

      const logo = await this.loadImage(logoUrl)
      const watermarkWidth = (this.canvas.width / 100) * 4
      const watermarkXPosition = (this.canvas.width / 100) * 2
      const watermarkYPosition = (this.canvas.height / 100) * 2
      const posX = this.canvas.width - watermarkWidth - watermarkXPosition
      const posY = this.canvas.height - watermarkWidth - watermarkYPosition

      this.context.drawImage(logo, posX, posY, watermarkWidth, watermarkWidth)
    },
    addTextWatermark(text: string) {
      if (!text) {
        return
      }

      const fontSize = (this.canvas.width / 100) * 1
      this.context.font = `${fontSize}px sans-serif`
      this.context.fillStyle = "#00000036"
      const textWidth = this.context.measureText(text).width
      const textHeight = fontSize
      const textX = 5
      const textY = this.canvas.height - textHeight

      this.context.fillRect(
        textX - 2,
        textY - textHeight - 2,
        textWidth + 4,
        textHeight + 4
      )
      this.context.fillStyle = "#fff"
      this.context.fillText(text, textX, textY)
    },

    async convertCanvasToImageBlob(canvas: HTMLCanvasElement): Promise<Blob> {
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) =>
            blob ? resolve(blob) : reject(new Error("Canvas is empty")),
          "image/png",
          1
        )
      })
    },
    async drawImageToCanvas(
      imageSrc: string
    ): Promise<DrawImageToCanvasResult> {
      const image = await this.loadImage(imageSrc)
      const canvas = document.createElement("canvas")
      const context = canvas.getContext("2d") as CanvasRenderingContext2D

      canvas.width = image.width
      canvas.height = image.height

      context.drawImage(image, 0, 0, canvas.width, canvas.height)

      return { canvas, context }
    },
    async loadImage(src: string): Promise<HTMLImageElement> {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = () => reject(new Error("Failed to load image"))
        img.crossOrigin = "anonymous"
        img.src = src
      })
    },
  },
})
